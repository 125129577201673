import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function NotFound() {
  return (
    <Layout>
      <Seo title="trang này không (chưa) tồn tại" />
      <div>
        <p>Có phải sầu vạn cổ</p>
        <p>Chất trong hồn chiều nay?</p>
      </div>
    </Layout>
  )
}
